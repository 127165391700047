// dev
const baseUrl = "http://voyance-lb-1814763255.ap-south-1.elb.amazonaws.com/";

//dev secondary
// const baseUrl = "http://3.111.34.132:7400/";

//Production test
// const baseUrl = "http://13.201.23.226:7890/";

// Production Url
// const baseUrl = "https://api.voyanceecg.com/";

export { baseUrl };
