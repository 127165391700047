import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../constants/config";
import { apiCall } from "../../api/api_index";
import { showLoading } from "../slice/loadingslice";

const createAccountAsyncThunk = (method, type, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const token = localStorage.getItem("token");
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data);
    const apiRequest = {
      method: method,
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: "Token " + localStorage.getItem("token"),
          }
        : {
            "Content-Type": "application/json",
            accept: "application/json",
          },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  });

export const userLogin = createAccountAsyncThunk(
  "post",
  "userLogin",
  "voyance_admin/logging/admin_login/"
);
export const addNewDevice = createAccountAsyncThunk(
  "post",
  "addNewDevice",
  "voyance_admin/device_info/add_new_device/"
);

export const GetDevicesList = createAccountAsyncThunk(
  "post",
  "GetDevicesList",
  "voyance_admin/device_info/get_device/"
);
export const GetUsersList = createAccountAsyncThunk(
  "post",
  "GetUsersList",
  "voyance_admin/user_info/user_get_info/"
);
export const DeleteDevice = createAccountAsyncThunk(
  "DELETE",
  "DeleteDevice",
  "voyance_admin/device_info/remove_device/"
);

export const GetCardioList = createAccountAsyncThunk(
  "post",
  "GetCardioList",
  "voyance_admin/cardio_admin/cardio_fetch/"
);

export const GetOtaHistory = createAccountAsyncThunk(
  "get",
  "GetOtaHistory",
  "voyance_admin/ota/ota_history/"
);

export const getPlanHistory = createAccountAsyncThunk(
  "post",
  "getPlanHistory",
  "voyance_admin/subscription_plan/get_plan_details/"
);

export const cardioFeesUpdation = createAccountAsyncThunk(
  "post",
  "cardioFeesUpdation",
  "voyance_admin/cardio_admin/cardio_fees_updation/"
);

export const couponsFetch = createAccountAsyncThunk(
  "get",
  "couponsFetch",
  "voyance_admin/coupon/coupons_fetch/"
);

export const cardioPayment = createAccountAsyncThunk(
  "post",
  "cardioPayment",
  "voyance_admin/cardio_admin/cardio_payment/"
);

export const generateCoupons = createAccountAsyncThunk(
  "post",
  "generateCoupons",
  "voyance_admin/coupon/generate_coupons/"
);

export const deleteCoupon = createAccountAsyncThunk(
  "post",
  "deleteCoupon",
  "voyance_admin/coupon/delete_coupon/"
);

export const updateVoyanceLite = createAccountAsyncThunk(
  "post",
  "updateVoyanceLite",
  "voyance_admin/subscription_plan/update_voyance_lite/"
);

export const updateUserPlan = createAccountAsyncThunk(
  "post",
  "updateUserPlan",
  "voyance_admin/subscription_plan/update_user_plan/"
);

export const getPendingReportList = createAccountAsyncThunk(
  "post",
  "getPendingReport",
  "voyance_admin/cardio_admin/cardio_document_status/"
);

export const deviceFilter = createAccountAsyncThunk(
  "post",
  "getDeviceFilter",
  "voyance_admin/device_info/device_filter/"
);
///voyance_admin/cardio_admin/cardio_filter_search/
export const cardioFilterSearch = createAccountAsyncThunk(
  "post",
  "getcardioFilterSearch",
  "voyance_admin/cardio_admin/cardio_filter_search/"
);



export const searchUser = createAccountAsyncThunk(
  "post",
  "getSearchUser",
  "/voyance_admin/user_info/search_user/"
)


export const devicesUsersModalAction = createAccountAsyncThunk(
  "post",
  "getDevicesUsersModalAction",
  "/voyance_admin/user_info/user_and_device_mapping/"
)


